import React from "react"
import { PageProps } from "gatsby"
import { Query } from "../../types/graphql-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/utils/PageTitle"
import ContactForm from "../components/Contact/ContactForm"
import { ja_description } from "../components/const"

const Contact: React.FC<PageProps<Query>> = ({ location }) => {
  return (
    <Layout location={location} selectPageName={"Contact"}>
      <SEO
        title="Contact"
        description={ja_description}
        lang={"ja"}
        twitterTitle={"contact"}
      />
      <PageTitle title="Contact" />
      <ContactForm />
    </Layout>
  )
}

export default Contact
